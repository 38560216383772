import React, { useState, useEffect } from 'react'
import Alert from "../components/alert"
import { CSSTransition } from 'react-transition-group';
import classNames from "classnames"
import Autocomplete from "../components/autocomplete"
import axios from 'axios';
import { getUser} from "../services/auth"
import {useEmpreses,especific} from "../helpers/useEmpreses"

const TransitionGeoGranjes = props => {  
  const [naus,setNaus] = useState([]);
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [granjaSelected,setGranjaSelected]=useState("");
  const [granjaStringSelected, setGranjaStringSelected] = useState("");
  const [nauSelected, setNauSelected] = useState("");
  const [nauStringSelected, setNauStringSelected] = useState("");
  const [isLoading, setLoading] = useState(false)
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [granjaGeoSelected,setGranjaGeoSelected]=useState("");
  const [allGranjes, setAllGranjes] = useState([]);
  const [allNaus,setAllNaus] = useState([]);
  const [arrayGranjesAutocomplete, setArrayGranjesAutocomplete] = useState([]);
  const [onlyOneNau, setOnlyOneNau] = useState(false)
  const [geoActive,setGeoActive] = useState(false)

  var empreses=useEmpreses()

  const getEmpresa =(idEmpresa) =>{
    var empresa="";
    for (var _empresa in empreses.allEmpresa.nodes){
        if (empreses.allEmpresa.nodes[_empresa].idEmpresa==idEmpresa) {
          empresa=empreses.allEmpresa.nodes[_empresa]
          break;
        }
    }
    return empresa;
  }

  useEffect( () => {
    var empresa=""
    if (getUser()) empresa=getEmpresa(getUser().idEmpresa);
    console.log("empresa= "+empresa)
    setAllGranjes(especific(empresa.allGranjes,getUser()))
    //setAllGranjes(empresa.allGranjes);
    setAllNaus(empresa.allNaus);

    //const objectGranjes = Object.entries(empresa.allGranjes);
    console.log("nombre total de granjes "+empresa.allGranjes.length)
    console.log("nombre total de granjes especifiques "+especific(empresa.allGranjes,getUser()).length)
    
    const objectGranjes = Object.entries(especific(empresa.allGranjes,getUser()))
    var _arr = [];
    objectGranjes.forEach(([key, value]) => {
      var item = {};
      item.value = value.marca;
      item.label = value.marca + "-" + value.nomMarca;
      _arr.push(item);
    });
    setArrayGranjesAutocomplete(_arr)

    if (!latitude && !longitude) {
      console.log("geo?"+navigator.geolocation.getCurrentPosition)
      if (navigator.geolocation.getCurrentPosition) setGeoActive(true)
      else setGeoActive(false)
      navigator.geolocation.getCurrentPosition (
        function(position) {
            setLoading(true)
            console.log("Lat: " + position.coords.latitude + "\n Lon: " + position.coords.longitude);
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            axios.defaults.headers.common['Authorization'] =  getUser().token;
            let url="/auth/getGeoGranja/"+position.coords.latitude+"/"+position.coords.longitude;
            axios.get(url).then(response => {
              console.log("geo search result="+JSON.stringify(response.data))
              setGranjaGeoSelected(response.data.marca)
              setGranjaSelected(response.data.marca)
              setGranjaStringSelected(response.data.descripcio)
              findNausbyGranja(empresa.allNaus,response.data.marca);
              //358BT aquesta té 2
              //findNausbyGranja(empresa.allNaus,"358BT");
              setLoading(false)
            }).catch(error => {
                setLoading(false)
                console.log("error=>"+error)
            })
        },
        function(error){
            alert(error.message);
        }, {
            enableHighAccuracy: true
            ,timeout : 5000
        }
      )
    }
 
  },[])

  const findNausbyGranja=(_allNaus,_marca)=>{
    var _results=[];
    console.log("findnausbygranja "+_marca)
    for (var i=0;i<_allNaus.length;i++){
      //console.log("marca "+_allNaus[i].marca +"=?"+_marca)
      if(_allNaus[i].marca === _marca) {
       // console.log("trobada "+_marca+", tenim la nau"+_allNaus[i].nau+", amb nom "+allNaus.nomnau)
        var nau={};
        nau.nau=_allNaus[i].nau;
        nau.nomnau=_allNaus[i].nomnau;
        _results.push(nau);
      }
    }
    if (_results.length==1) {
      setOnlyOneNau(true)
      setNauSelected(_results[0].nau)
      setNauStringSelected(_results[0].nomnau)
    } else {
      setOnlyOneNau(false)
      setNaus(_results)
    }
      
  }

  const handleNauSelected= (e) =>{
    setNauSelected(e.target.value)
    setNauStringSelected(e.target.options[e.target.selectedIndex].label)
  }
  const handleSelectGranjaChange= (text,index) =>{
    let granja= text.split("-");
    let code= granja[0].trim();
    let granjaText=granja[1].trim()
    code = code.replace(/^\s+/g, '');
    granjaText=granjaText.replace(/^\s+/g, ''); 
    setGranjaStringSelected(granjaText)
    setGranjaSelected(code)
    console.log("granja seleccionada= "+code+", "+granjaText)
    if (!text) {
        setNaus([]);
        setNauSelected("")
        return
    }
    findNausbyGranja (allNaus,code)
  }

  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const handleCancelGeo =()=> {
    setGranjaGeoSelected("")
    setGranjaSelected("")
    setGranjaStringSelected("")
    setNauSelected("")
    setNauStringSelected("")
    setOnlyOneNau(false)
  }
  
  const onExit = () =>{
      
  }

  const handleClickUp = (e) => {
    props.callbackValues(granjaSelected, nauSelected) 
    //console.log("granja = "+granjaSelected+",nau="+nauSelected)

    e.preventDefault();

    if(props.stepActive===props.index && !granjaSelected) {
        setShowAlertStep(true)
    }else {
      props.handleStepActiveUp(props.stepActive)
      setShowAlertStep(false)
    }
  }

  const handleClickDown = (e) => {
    e.preventDefault();
    props.handleStepActiveDown(props.stepActive)
    return
  }

  return (
    <>
      <CSSTransition
        in={props.stepActive === props.index}
        timeout={300}
        onExit={onExit}
        classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
        unmountOnExit
      >
      <div className="steps-panel">

      
          { granjaGeoSelected ? 
              <div className="field-wrapper" >  
                <b>Granja Geo localitzada:</b>
                <span> {granjaSelected} - {granjaStringSelected} </span>
                  <span><button className="btn-secondary btn--xs steps-nav-init"  onClick={handleCancelGeo}>...</button></span>
                </div>
            :
            <div className="field-wrapper">       
              <Autocomplete suggestions={arrayGranjesAutocomplete} inputLabel="Origen" inputName="origen-mares" callbackValue={handleSelectGranjaChange}/>
            </div>
            
        }        
            
          { onlyOneNau ? 
              <div className="field-wrapper">  
                <b>Nau:</b>
                <span> {nauSelected} - {nauStringSelected}</span>
             </div>
              :
              <div className="field-wrapper field-wrapper-select">
                <select className={nauSelected && 'dirty'} name="nau" required id="select" value={nauSelected} onChange={handleNauSelected}>
                  <option value=""></option>
                  { naus.map((nau) => (
                    <option key={nau.nau} value={nau.nau}>{nau.nomnau}</option>
                  ))
                  }
                </select>
                <label htmlFor="naus">Naus</label>
              </div>
            
          }
         
       

         <div className="steps-nav">
            <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
              <span>Prev</span>
            </button>
            <button onClick={handleClickUp} className='steps-nav-btn steps-nav-btn-next'>
              <span>Next</span>
            </button>
         </div>
         { isLoading && (
              <div className="overlay-loading"></div>
              )}
      </div>
      </CSSTransition>
      <CSSTransition
        in={showAlertStep}
        timeout={300}
        classNames={'alertright'}
        unmountOnExit
      >
        <Alert type="error">
          <div className="alert-message">
            El camp Origen Marca és obligatori
          </div>
          <span className="alert-close" onClick={closeAlertStep}>
            <i className="icon-close"></i>
          </span>
        </Alert>
      </CSSTransition>
    </>
    )
}

export default TransitionGeoGranjes