import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Alert from "./alert"
 
const TransitionBaixa = props => {
  const [foto, setFoto] = useState([]);
  const [animals, setAnimals] = useState("");
  const [lot, setLot] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [dateString,setDateString] = useState("");
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const [motiu, setMotiu]=useState("");
    
  const buildForm=(callback)=> {
    const formData = new FormData();
    if (foto) formData.append('foto', foto);
    const data={}
    data["animals"]=animals;
    data["data"]=dateString;
    data["lot"]=lot;
    data["motiu"]=motiu;
    callback(data,formData);
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
 
  const onChangeFoto =(e) => {
    setFoto(e.target.files[0]);
  }
  const onChangeLot =(e) => {
    setLot(e.target.value);
  }
  const handleAnimals = (e) => {
    setAnimals(e.target.value)
  }
  
  const handleDate = data => {
   let dataTime=formatDate(data) 
   setStartDate(data);
   setDateString(dataTime);
  }
  const handleClickUp = (e) => {
    e.preventDefault();
    buildForm(function(data,form,err){
      if (err){
        setMessageAlertStep("Error al pujar fitxers")
        console.log("err="+err)
        setShowAlertStep(true)
        return
      }else {
       // console.log("callback done, viam que tenim = "+form) 
        props.callbackValues(form,data)
      }
    })
    
  }
  const handleClickDown = (e) => {
    e.preventDefault();
    if (foto) {
      var res=window.confirm('Atenció, es perdran els fitxers que has pujat') 
      if (res) props.handleStepActiveDown(props.stepActive)
    }else props.handleStepActiveDown(props.stepActive)
  }
  const handleMotiu= (e) =>{
    setMotiu(e.target.options[e.target.selectedIndex].label)
  }

  useEffect( () => {
    var data =new Date();
    let dataTime=formatDate(data) 
    if (!dateString || dateString==="") setDateString(dataTime)
    setStartDate(data);
  }, []);

  return (
      <span>
        <CSSTransition
          in={props.stepActive === props.index}
          timeout={300}
          classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
          unmountOnExit>

          <div className="steps-panel">
             
              <div className={'field-wrapper field-wrapper-datepicker dirty'}>
                <DatePicker dateFormat="dd/MM/yyyy" maxDate={new Date()} selected={startDate} name="data" onChange={handleDate} />
                <label htmlFor="date">Data </label>
              </div>
              <div className="field-wrapper">
                  <input className="field-input dirty" type="text" name="animals" value={animals} onChange={handleAnimals}/>
                  <label htmlFor="animals">Animals </label>

              </div> 
              <div className="field-wrapper">
                  <input className="field-input dirty" type="text" name="lot" onChange={onChangeLot} maxLength="5"/>
                  <label htmlFor="lot">Lot</label>  
              </div>
              <div className="field-wrapper field-wrapper-file">
                  <input className="field-input dirty" type="file" name="foto" onChange={onChangeFoto} />
                  <label htmlFor="foto">Foto</label>  
                  <span>{foto.name}</span>                
              </div>  
              <div className="field-wrapper field-wrapper-select">
                  <select className="dirty" name="motiu" required id="select" value={motiu} onChange={handleMotiu}>
                    <option key="MENINGITIS" value="MENINGITIS">MENINGITIS</option>
                    <option key="DELGADO" value="DELGADO">DELGADO</option>
                    <option key="PULMON" value="PULMÓN/NEUMONIA">PULMÓN/NEUMONIA</option>
                    <option key="TOS" value="TOS">TOS</option>
                    <option key="HERNIA" value="HERNIA">HERNIA</option>
                    <option key="DIARREA" value="DIARREA">DIARREA</option>
                    <option key="COJO" value="COJO">COJO</option>
                    <option key="PEQUEÑO" value="PEQUEÑO/PRIM">PEQUEÑO/PRIM</option>
                    <option key="SALDO" value="SALDO/MALO">SALDO/MALO</option>
                    <option key="SUBITA" value="SUBITA">SUBITA</option>
                    <option key="ENFERMERIA" value="ENFERMERIA">ENFERMERIA</option>
                    <option key="DESCONOCIDO" value="DESCONOCIDO">DESCONOCIDO</option>
                    <option key="COLAS" value="COLAS">COLAS</option>
                    <option key="PELEA" value="PELEA">PELEA</option>
                  </select>
                  <label htmlFor="naus">Motiu</label>
              </div>             
             
            <div className="steps-nav">
                <div className="form-submit-wrapper">
                <button className="btn-secondary btn--xs steps-nav-init" onClick={handleClickDown}>Modificar</button>
                <button className="btn-secondary btn--xs steps-nav-init" onClick={handleClickUp}>Confirmar</button>
              </div>
            </div>
        </div>

        </CSSTransition>

        <CSSTransition
          in={showAlertStep}
          timeout={300}
          classNames={'alertright'}
          unmountOnExit>

            <Alert type="error">
              <div className="alert-message">
                  {messageAlertStep}
              </div>
              <span className="alert-close" onClick={closeAlertStep}>
                 <i className="icon-close"></i>
              </span>
            </Alert>
      </CSSTransition>
      </span>

    )
}

export default TransitionBaixa