import React, { useState } from 'react'
import { navigate} from "gatsby"
import Header from "../components/header"
import HeaderMenu from "../components/header-menu"
import Footer from "../components/footer"
import Alert from "../components/alert"
import { CSSTransition } from 'react-transition-group';
import { getUser} from "../services/auth"
import TransitionGeoGranjes from "../components/transitionGeoGranjes"
import TransitionBaixa from "../components/transitionBaixa"
import axios from 'axios';

export default () => {
  //Step controls
  
  const [stepActive, setStepActive] = useState(1);
  const [stepPrev, setStepPrev] = useState(0);
  //origenData
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const [isLoading, setLoading] = useState(false)
  const [granjaSelected, setGranjaSelected] = useState("");
  const [nauSelected, setNauSelected] = useState("");
  const [granjesMares, setGranjesMares] = useState([]);
   
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }

  const handleStepUp = (value) => {
   if (value!==5) {
      setStepPrev(value);
      setStepActive(value + 1);
   }
  }

  const handleStepDown = (value) => {
     if (value!==1) {
      setStepPrev(value);
      setStepActive(value - 1);
     }
  }

  const handleConfirm = (_formData,_data) => {
    setLoading(true)
    axios.defaults.headers.common['Authorization'] =  getUser().token;
    _formData.append("granja",granjaSelected)
    _formData.append("nau",nauSelected)
    _formData.append("data",_data.data)
    _formData.append("animals",_data.animals)
    _formData.append("lot",_data.lot)
    _formData.append("motiu",_data.motiu)
    
  axios({
      method: "post",
      url: "/auth/insertBaixa",
      data: _formData,
      headers: { "Content-Type": "multipart/form-data" }
    }).then(res => {
      setLoading(false)
      if (res.data.error) {
        console.log("ERROR AL INSERTAR = "+JSON.stringify(res.data) )   
        setMessageAlertStep(res.data.error)
        setShowAlertStep(true)
      } else{
        navigate("/mainMenu")
      }
   }).catch(error => {
      setMessageAlertStep(error.message)
      setShowAlertStep(true)
      setLoading(false)
   })  
  
  }

  const handleGranja = (granja,nau) => {
    setGranjaSelected(granja);
    setNauSelected(nau);
  }

  const handleFormData = (form,data) => {
    handleConfirm(form,data)
  }
    
  return (
      <React.Fragment>
        <div className="granjatec-wrapper">
          <Header/>
          <HeaderMenu />
          <div className="main-container">
              <section className="section heading-opt">
                <div className="heading-opt-title">
                  <h2 className="h5">Baixes</h2>
                </div>
              </section>
              <section className="section form-opt destete-engreix">
                <form className="steps">

                  <TransitionGeoGranjes index={1} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        callbackValues={handleGranja} />
                   
                  <TransitionBaixa index={2} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        callbackValues={handleFormData} />

                </form>
              </section>
              { isLoading && (
              <div className="overlay-loading"></div>
              )}
            </div>
            <CSSTransition
              in={showAlertStep}
              timeout={300}
              classNames={'alertright'}
              unmountOnExit >
              <Alert type="error">
                <div className="alert-message">
                  {messageAlertStep}
                </div>
                <span className="alert-close" onClick={closeAlertStep}>
                  <i className="icon-close"></i>
                </span>
              </Alert>
            </CSSTransition>
          <Footer/>
        </div>
    </React.Fragment>

)}